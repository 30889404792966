export const translateAudioToText = async (
  audioBlob,
  sourceLanguage,
  targetLanguage,
  sessionID,
  conversationID
) => {
  console.log(
    "translateAudioToText called with:",
    JSON.stringify({
      audioBlob,
      sourceLanguage,
      targetLanguage,
      sessionID,
      conversationID,
    })
  );

  const formdata = new FormData();
  formdata.append("audio_file", audioBlob, "userRecoding.wav");
  formdata.append("source_language", sourceLanguage.language_code);
  formdata.append("target_language", targetLanguage.language_code);
  formdata.append("session_id", sessionID);
  formdata.append("conversation_id", conversationID);
  formdata.append("other", "gqLBR5gB85bA21rCiYFnDlpU5EK2");

  const requestOptions = {
    method: "POST",
    body: formdata,
  };

  try {
    const response = await fetch(
      "https://translate.bettermos.com/api/v1/audio-to-text",
      requestOptions
    );
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Failed to translate audio to text: ${response.status} ${response.statusText} - ${errorText}`
      );
    }

    const data = await response.json();
    console.log("translateAudioToText response:", JSON.stringify(data));
    return data;
  } catch (error) {
    console.error("Error in translateAudioToText:", error);
    throw error;
  }
};

export const textToAudio = async (
  translatedText,
  targetLanguage,
  targetGender,
  sessionID,
  conversationID
) => {
  console.log(
    "textToAudio called with:",
    JSON.stringify({
      translatedText,
      targetLanguage,
      targetGender,
      sessionID,
      conversationID,
    })
  );

  const formdata = new FormData();
  formdata.append("translated_text", translatedText);
  formdata.append("target_language", targetLanguage.language_code);
  formdata.append("target_gender", targetGender);
  formdata.append("session_id", sessionID);
  formdata.append("conversation_id", conversationID);
  formdata.append("other", "gqLBR5gB85bA21rCiYFnDlpU5EK2");

  const requestOptions = {
    method: "POST",
    body: formdata,
  };

  try {
    const response = await fetch(
      "https://translate.bettermos.com/api/v1/text-to-audio",
      requestOptions
    );
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Failed to convert text to audio: ${response.status} ${response.statusText} - ${errorText}`
      );
    }

    const blob = await response.blob();
    console.log("textToAudio response blob:", JSON.stringify(blob));
    return blob;
  } catch (error) {
    console.error("Error in textToAudio:", error);
    throw error;
  }
};
