import React, { useState } from "react";
import logo from "../../MOKIN_WHITE_TRANSP.png";
import "./Navbar.css";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { BiShareAlt } from "react-icons/bi"; // Using react-icons for the share icon

function Navbar({
  handleExportConversation, // Receive the export handler as a prop
}) {
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("signout successful");
        navigate("/Loginpage");
      })
      .catch((error) => console.log(error));
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    window.location.reload();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
      <div className="container-fluid">
        {/* Brand */}
        <a className="navbar-brand" href="#">
          <img
            src={logo}
            height="35"
            className="d-inline-block align-top"
            alt="Mokin Logo"
          />
        </a>

        {/* Right side for mobile: Refresh Button and Hamburger Menu */}
        <div className="d-flex d-lg-none align-items-center">
          {/* Refresh Button for Mobile */}
          <button
            type="button"
            className={`btn btn-light me-2 d-flex align-items-center justify-content-center ${isRefreshing ? "refreshing" : ""}`}
            onClick={handleRefresh}
            title="Refresh Page"
            disabled={isRefreshing}
          >
            <i className="bi bi-arrow-clockwise"></i>
          </button>

          {/* Hamburger Menu */}
          <button
            className={`navbar-toggler ${!isRefreshing ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDarkDropdown"
            aria-controls="navbarNavDarkDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="bar bar1"></span>
            <span className="bar bar2"></span>
            <span className="bar bar3"></span>
          </button>
        </div>

        {/* Navbar Links and Refresh Button for Large Screens */}
        <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
          <ul className="navbar-nav ms-auto align-items-center">
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="modal"
                data-bs-target="#helpModal"
                href="#"
              >
                Hulp
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="modal"
                data-bs-target="#aboutModal"
                href="#"
              >
                Over Mokin
              </a>
            </li>
            <li className="nav-item ms-lg-3">
              {/* Refresh Button for Large Screens */}
              <button
                type="button"
                className={`btn btn-light me-2 d-none d-lg-block ${isRefreshing ? "refreshing" : ""}`}
                onClick={handleRefresh}
                title="Refresh Page"
                disabled={isRefreshing}
              >
                <i className="bi bi-arrow-clockwise"></i>
              </button>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={handleLogout}
                href="#"
                title="Uitloggen"
              >
                Uitloggen
                <i className="bi bi-box-arrow-right ms-2"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={handleExportConversation}
                href="#"
                title="Export Conversation"
              >
                <span className="d-lg-none">Export </span>
                <BiShareAlt size={24} className="ms-2" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Help Modal */}
      <div
        className="modal fade"
        id="helpModal"
        tabIndex="-1"
        aria-labelledby="helpModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="helpModalLabel">
                Hoe gebruik ik Mokin?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Selecteer onderin het beeldscherm jouw eigen taal en de taal
                waar naar je wilt vertalen.
              </p>
              <p>
                Klik op de microfoonknop en begin met praten. Druk de knop
                nogmaals in als je klaar bent met praten. De gesproken tekst
                wordt nu automatisch vertaald en uitgesproken.
              </p>
              <p>Klik op de tekst om de vertaling nogmaals te horen!</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Sluiten
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* About Modal */}
      <div
        className="modal fade"
        id="aboutModal"
        tabIndex="-1"
        aria-labelledby="aboutModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="aboutModalLabel">
                Over Mokin®
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                Mokin is een mobiele app gemaakt door SwitchAI. Voor meer
                informatie ga naar:
              </p>
              <a
                href="https://mokin.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.mokin.nl
              </a>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Sluiten
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
