import React from "react";

function LoadingMessage({ sourceLanguage, targetLanguage, micId, sessionID, conversationID, timestamp }) {
  return {
    id: "loadingmessage",
    sessionID: sessionID,
    mic: micId,
    text: "...",
    userAudio: null,
    language: sourceLanguage,
    languageTr: targetLanguage,
    translation: "...",
    translationAudio: null,
    feedbackRating: 0,
    timestamp: timestamp, // Ensure timestamp is provided
  };
}

export default LoadingMessage;